html {
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
		Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
	background-color: white;
}

* {
	/* border: 1px solid red !important; */
}

.container {
	margin: 0.5em;
	margin-left: 2em;
	display: grid;
	align-items: center;
	grid-template-columns: 0.2fr;
	display: grid;
	justify-items: end;
	height: 12vh;
}

.navbar > ul.mobile {
	display: none;
}

.navbar {
	grid-row: 1;
	grid-column: 2;
	margin: 1em;

	opacity: 0;
	animation: fadeIn 2s ease-in-out 2s forwards;
}

.navbar > ul {
	display: flex;
	justify-content: space-between;
	align-items: center;
	list-style: none;
	font-weight: 500;
}

.navbar > ul > li {
	margin: 0 1em;
	padding: 1em;
	border-radius: 10px;
}

.navbar > ul > li:hover {
	color: #fff;
	background-color: #000;
	transition: 0.3s ease-in-out;
	cursor: pointer;
}

.logo {
	justify-self: end;
	opacity: 0;
	animation: fadeIn 2s ease-in-out 2s forwards;
}

.logo > img {
	height: 5em;
}

.main__container {
	display: grid;
	grid-template-columns: 1fr 1fr;
	margin: 1em;
	margin-bottom: 0;
	justify-items: center;
}

.main__projects {
	grid-column: 1/3;
	grid-row: 2;
}

.hero {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	color: #1d1d1f;
	text-align: center;
	padding: 20px;
	grid-row: 1;
	grid-column: 1 / 3;
	z-index: 1;
}

.hero-title {
	font-size: 3em;
	font-weight: 500;
	margin-bottom: 10px;
	opacity: 0;
	animation: fadeIn 3s ease-in-out forwards;
}

.hero-subtitle {
	font-size: 1.5em;
	margin-top: 0;
	opacity: 0;
	animation: fadeIn 3s ease-in-out 1s forwards;
}

.scroll-down {
	color: black;
	font-size: 2em;
	transition: transform 0.3s ease-in-out; /* Only transition the transform property */
	opacity: 0;
	animation: fadeIn 2s ease-in-out 2s forwards;
}

.scroll-down:hover {
	transform: scale(1.2); /* Increase the size of the arrow by 20% on hover */
}

.hero-image {
	width: 100%; /* The image will take up the full width of the container */
	object-fit: cover; /* The image will be scaled to cover the container while maintaining its aspect ratio */
	margin: 20px 0; /* This will add space above and below the image */
	position: relative; /* Ensure the image is not absolutely positioned */
	animation: fadeIn 2s ease-in-out 2s forwards;
	opacity: 0;
}

.hero-image-mobile {
	display: none;
	opacity: 0;

	animation: fadeIn 2s ease-in-out 2s forwards;
}

@keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

.about {
	display: flex;
	flex-direction: column;
	justify-content: start;
	align-items: center;
	height: 90vh; /* Adjust as needed */
	width: 100%;
	color: #1d1d1f; /* Dark text for contrast */
	font-family: Arial, sans-serif; /* Adjust as needed */
	padding: 2em;
	grid-column: 1/3;
	animation: fadeIn 2s ease-in-out 2s forwards;
	opacity: 0;
}

.carousel.carousel-slider {
	width: 60% !important;
	margin: 0 auto !important;
}

.about h1,
.contact-section h1,
.projects h1 {
	margin-bottom: 0.5em;
	font-size: 3em;
}

.contact-section {
	grid-column: 1/3;
	display: flex;
	flex-direction: column;
	justify-content: start;
	align-items: center;
	height: 80vh;
	margin-top: 4em;
	width: 100%;
	color: #1d1d1f;
	font-family: Arial, sans-serif;
	padding: 2em;
}

.about__content {
	max-width: 90vw; /* Adjust as needed */
	text-align: center;
}

.about__content p {
	margin-bottom: 1em;
	font-size: 1.5em;
	margin-top: 0;
}

.social-button {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	background-color: white;
	color: black;
	padding: 10px 20px;
	border-radius: 50px;
	border: none;
	transition: background-color 0.3s ease;
	text-decoration: none;
	margin: 0.5em;
	cursor: pointer;
}

.social-button img {
	width: 20px;
	height: 20px;
	margin-right: 10px;
}

.linkedin-button:hover {
	background-color: #0077b5; /* LinkedIn's brand color */
	color: white;
}

.twitter-button:hover {
	background-color: #0c83d4; /* Darker shade of Twitter's brand color */
	color: white;
}

.instagram-button:hover {
	background: #f09433;
	background: -moz-linear-gradient(
		45deg,
		#f09433 0%,
		#e6683c 25%,
		#dc2743 50%,
		#cc2366 75%,
		#bc1888 100%
	);
	background: -webkit-linear-gradient(
		45deg,
		#f09433 0%,
		#e6683c 25%,
		#dc2743 50%,
		#cc2366 75%,
		#bc1888 100%
	);
	background: linear-gradient(
		45deg,
		#f09433 0%,
		#e6683c 25%,
		#dc2743 50%,
		#cc2366 75%,
		#bc1888 100%
	);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );
	color: white;
	color: white;
}

.github-button:hover {
	background-color: #333; /* GitHub's brand color */
	color: white;
}

.about__projects {
	display: none;
	justify-content: space-between;
	gap: 20px;
}

.about__project {
	position: relative;
	width: 45%; /* Adjust as needed */
	overflow: hidden;
	border: 1px solid #ddd;
	border-radius: 10px;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
	transition: box-shadow 0.3s ease;
}

.about__project:hover {
	box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.about__project img {
	width: 100%;
	height: 200px; /* Adjust as needed */
	object-fit: cover;
	transition: opacity 0.3s ease;
}

.about__project:hover img {
	opacity: 0.5;
}

.about__project .content {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	opacity: 0;
	transition: opacity 0.3s ease;
	color: white;
	padding: 20px;
	box-sizing: border-box;
	text-align: center;
}

.about__project:hover .content {
	opacity: 1;
}

.about__project .content h2,
.about__project .content p {
	margin: 0;
}

.about__project .content a {
	margin-top: 10px;
}

.timeline {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 20px 0;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Adjust this to get the desired shadow effect */

	margin-bottom: 3em;
	border-radius: 10px;
}

.timeline::after {
	content: "";
	display: table;
	clear: both;
}

.experience {
	width: 30%; /* Adjust as needed */
	border-left: 2px solid #ddd;
	position: relative;
	padding-left: 10px;
	padding-right: 10px;
	padding-bottom: 5px;
}

.experience h3 {
	margin: 0 0 10px;
}

.experience p {
	margin: 0;
}

.experience:first-child::before {
	content: "";
	position: absolute;
	top: 0;
	left: -10px;
	width: 20px;
	height: 20px;
	background-color: #ddd;
	border-radius: 50%;
}

.contact-section {
	display: flex;
	flex-direction: column;
	align-items: center;
	animation: fadeIn 2s ease-in-out 2s forwards;
	opacity: 0;
}

.contact-section h1 {
	margin-bottom: 0.5em;
	font-size: 3em;
}

.contact-form {
	width: 100%;
	max-width: 40vw;
}

.contact-form input,
.contact-form textarea {
	width: 100%;
	padding: 15px;
	margin-bottom: 20px;
	border: none;
	border-radius: 5px;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
	font-size: 1em;
	box-sizing: border-box; /* Add this line */
}

.contact-form textarea {
	resize: vertical;
	min-height: 150px;
}

.contact-form button {
	width: 100%; /* Adjust as needed */
	font-weight: 600;
	padding: 15px;
	margin: 0 auto; /* Centers the button */
	display: block; /* Allows for centering with margin */
	border: none;
	border-radius: 5px;
	background-color: #007bff;
	color: white;
	font-size: 1em;
	cursor: pointer;
	transition: background-color 0.3s ease;
}

.contact-form button:hover {
	background-color: #0056b3;
}

.notification {
	color: white;
	background-color: #2ace2a; /* Lighter green */
	padding: 10px;
	margin-top: 10px;
	border-radius: 5px;
	animation: fadeOut 5s ease-in-out; /* Fade out after 3 seconds */
}

.projects {
	grid-column: 1/3;
	width: 100%;
	justify-content: start;
	align-items: center;
	width: 100%;
	color: #1d1d1f;
	padding: 2em;
	animation: fadeIn 2s ease-in-out 2s forwards;
	opacity: 0;
}

.projects h1 {
	margin-bottom: 0.5em;
	font-size: 3em;
	text-align: center;
}

.grid {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: 1fr 1fr;
	gap: 20px;
	width: 90%;
	margin: 0 auto;
}

.project-card {
	position: relative;
	overflow: hidden;
	border-radius: 10px;
	max-height: 40vh;
	display: flex;
	justify-content: center;
	align-items: center;
}

.project-card img {
	width: 100%;
	height: auto;
	transition: transform 0.3s ease;
}

.project-card:hover img {
	transform: scale(1.1);
}

.overlay {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: rgba(0, 0, 0, 0.6);
	overflow: hidden;
	width: 100%;
	height: 0;
	transition: 0.5s ease;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.project-card:hover .overlay {
	height: 100%;
}

.overlay h3 {
	color: white;
	font-size: 20px;
	margin: 0;
}

.popup {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background: white;
	padding: 20px;
	z-index: 1000;
	border-radius: 10px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
	width: 80%;
	max-width: 500px;
}

.popup h2 {
	margin-top: 0;
	color: #333;
}

.popup p {
	margin: 20px 0;
	line-height: 1.6;
}

.popup ul {
	padding: 0;
	list-style-type: none;
}

.popup li {
	display: inline-block;
	margin-right: 10px;
	padding: 5px 10px;
	background: #6d00ff;
	color: white;
	border-radius: 5px;
}

.popup__image {
	width: 100%;
	height: auto;
	margin-bottom: 1em;
}

.overlay__popup {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(0, 0, 0, 0.5);
	z-index: 999;
}

.project-card .overlay ul {
	padding: 0;
	list-style-type: none;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin-top: 10px;
}

.project-card .overlay ul li {
	background: #6d00ff;
	color: white;
	font-weight: 600;
	padding: 5px 10px;
	margin: 5px;
	border-radius: 5px;
	font-size: 1em;
}

@media (max-width: 600px) {
	.grid {
		grid-template-columns: 1fr;
	}
}
@keyframes fadeOut {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
		visibility: hidden;
	}
}
/* Responsive styles */

@media screen and (max-width: 735px) {
	.hero-image {
		display: none;
	}

	.hero-image-mobile {
		display: block;
		height: 50vw;
	}

	.hero-title {
		font-size: 2em; /* Further reduce the font size for smaller screens */
	}

	.hero-subtitle {
		font-size: 1.2em; /* Further reduce the font size for smaller screens */
	}

	.scroll-down {
		font-size: 1.5em; /* Further reduce the size of the arrow for smaller screens */
	}
}

/* From width 425px, adjust the hero-image-mobile */
@media screen and (min-width: 320px) and (max-width: 480px) {
	.hero-image-mobile {
		display: block;
		height: 38vh;
	}
}

@media screen and (max-width: 480px) {
	.hero-title {
		font-size: 2em;
		font-weight: 700;
	}

	.hero-subtitle {
		font-size: 1.3em; /* Further reduce the font size for even smaller screens */
	}

	.hero-image {
		display: none;
	}

	.logo > img {
		height: 8vh;
	}

	.hero {
		max-width: 90vw;
		margin: auto;
		padding: 0;
	}

	.navbar {
		margin: 0.5em;
	}

	.navbar > .classic {
		display: none;
	}

	.navbar > ul.mobile {
		display: flex;
		margin: 0;
		padding: 0;
		font-size: 0.8em;
	}

	.scroll-down {
		font-size: 1em; /* Further reduce the size of the arrow for even smaller screens */
	}

	.container {
		margin-left: 2em;
		display: grid;
		align-items: center;
		grid-template-columns: 0.2fr;
		display: grid;
		justify-items: end;
		height: 10vh;
	}

	.about {
		height: 100%;
		opacity: 0;
		animation: fadeIn 2s ease-in-out 2s forwards;
	}

	.timeline {
		flex-direction: column-reverse;
		align-items: normal;
	}

	.experience {
		width: 100%;
		border-left: none;
		border-top: 2px solid #ddd;
		padding-left: 0;
		padding-top: 10px;
		padding-bottom: 20px;
	}

	.about h1 {
		font-size: 2em;
	}

	.about p {
		font-size: 1.1em;
	}

	.contact-section h1 {
		font-size: 2em;
	}

	.contact-section {
		height: 70vh;

		opacity: 0;
		animation: fadeIn 2s ease-in-out 2s forwards;
	}

	.contact-form {
		max-width: 90vw !important;
	}
}
